import { Controller } from '@hotwired/stimulus'
import {
  Swiper, EffectFade, Navigation, Pagination, Parallax,
} from 'swiper'

export default class extends Controller {
  static targets = ['slider']

  swiper = null

  loadedVideosIdx = []

  connect() {
    console.log("HERE!")
    // eslint-disable-next-line no-underscore-dangle
    const customOptions = window._featured_category_swiper_options || {}

    const defaultProps = {
      initialSlide: 0,
      loop: true,
      centeredSlides: true,
			slidesPerView: 'auto',
      spaceBetween: 4,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
        disabledClass: 'swiper-button-disabled',
        hiddenClass: 'swiper-button-disabled',
      },
      pagination: {
        type: 'bullets',
        el: '.swiper-pagination',
        clickable: true,
      },
    }

    Swiper.use([Navigation, Pagination, Parallax, EffectFade])
    this.swiper = new Swiper(this.sliderTarget, { ...defaultProps, ...customOptions })

    setTimeout(() => {
      this.sliderTarget.classList.remove('opacity-0')
    }, 500)


    this.onClickFunc = e => this.onClick(e)
    this.sliderTarget.addEventListener('click', this.onClickFunc)
  }

  disconnect() {
    this.sliderTarget.removeEventListener('click', this.onClickFunc)
    document.removeEventListener('click', this.onClickFunc)
  }

  onClick(e) {
    if (e.target.classList.contains('enable-fullscreen')) {
      this.sliderTarget.classList.add('fullscreen')
    } else {
      if (e.target.closest('.disable-fullscreen')) {
        this.sliderTarget.classList.remove('fullscreen')
      }
    }
  }
}
