import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['link']

  onClick(e) {
    console.log("H1")

    if (!e.target.closest('a')) {
      console.log("H2")
      location.href = this.linkTarget.href
    }
  }
}
