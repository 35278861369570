import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'container', 'toggle']

  connect() {
    console.log("connect")

    this.onKeyPressFunc = e => this.onKeyPress(e)
    this.toggleTarget.addEventListener('keypress', this.onKeyPressFunc)

    this.onClickFunc = e => this.onClick(e)
    document.addEventListener('click', this.onClickFunc)
  }

  disconnect() {
    this.toggleTarget.removeEventListener('keypress', this.onKeyPressFunc)
    document.removeEventListener('click', this.onClickFunc)
  }

  onKeyPress(e) {
    if (e.keyCode == 32) {
      this.inputTarget.checked = !this.inputTarget.checked
    }
  }

  onClick(e) {
    if (this.containerTarget.contains(e.target)) {
      return
    }

    this.inputTarget.checked = false;
  }
}
