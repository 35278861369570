import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['root']

  connect() {
  }

  submit() {
    const event = new CustomEvent('filter-submit', { bubbles: true })
    this.rootTarget.dispatchEvent(event)
  }
}
