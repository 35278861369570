import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from '@rails/activestorage';

export default class extends Controller {
  static targets = [ "input", "preview", "result" ]

  connect() {
    this.directUploadUrl = document.querySelector("meta[name='direct-upload-url']").getAttribute("content")
  }

  upload() {
    var input = this.inputTarget

    var file = input.files[0]

    let reader = new FileReader()
    reader.onload = () => {
      this.previewTarget.setAttribute("src", reader.result);
      this.previewTarget.classList.remove("hidden");
    }
    reader.readAsDataURL(file)
  }
}
