import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  connect() {
    window.intlTelInput(this.inputTarget, {
      initialCountry: "ae",
      separateDialCode: true,
      hiddenInput: "phone_full",
      customContainer: 'w-full',
      utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
    })
  }
}


