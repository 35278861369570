import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'input', 'clearButton']

  onSubmit() {
    if (this.inputTarget.value.length > 0) {
      this.clearButtonTarget.classList.remove('hidden')
    }
  }

  submit() {
    this.formTarget.requestSubmit()
  }

  clearSearch() {
    this.inputTarget.value = null
    this.formTarget.requestSubmit()
    this.clearButtonTarget.classList.add('hidden')
  }
}
