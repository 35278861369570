import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['passwordField', 'eyeImage']

  static values = {
    eyeOn: String,
    eyeOff: String
  }

  toggleVisibility() {
    if (this.passwordFieldTarget.type === 'password') {
      this.passwordFieldTarget.type = 'text'
      this.eyeImageTarget.src = this.eyeOffValue
    } else {
      this.passwordFieldTarget.type = 'password'
      this.eyeImageTarget.src = this.eyeOnValue
    }
  }
}
