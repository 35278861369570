import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['container', 'map', 'marker', 'exactLocation', 'exactLocationContainer', 'saveButton', 'distance', 'distanceUnits',
   'autocomplete', 'autocompleteContainer', 'autocompleteClear', 'resultLat', 'resultLng', 'resultLocation', 'resultRadius', 'resultExactLocation']

  static values = {
    lat: String,
    lng: String,
    mode: String,
    exact: Boolean
  }

  globeModeZoom = 1
  preciseModeZoom = 15
  approximateModeZoom = 12.5
  controlsAppearZoom = 8
  geoapifyKey = 'c6916af4b16d436ba76dae58159aaaf9'

  connect() {
    this.state = 'none'
    this.zoom = 0
    this.stoppedMoving = false

    this.resultExactLocationTarget.value = this.exactValue

    if (this.modeValue == 'editor') {
      if (this.latValue && this.lngValue) {
        const center = [this.lngValue, this.latValue]
        const zoom = this.exactValue ? this.preciseModeZoom : this.approximateModeZoom

        this.initializeMap(center, zoom)
        this.changeStateTo(this.exactValue ? 'exact' : 'approximate')
      } else {
        const center = [5, 5]
        const zoom = this.globeModeZoom

        this.initializeMap(center, zoom, 'globe')
        this.changeStateTo('globe')
      }
    }
  }

  initializeMap(center, zoom) {
    mapboxgl.accessToken = 'pk.eyJ1IjoiYm9uZGRtaXRyeSIsImEiOiJjanJ3OTh2Z2MwOWg1M3luaG96YzUwdW1yIn0.lXfszgM-i08_2swfUlKWxw';

    this.mapTarget.style.height = this.containerTarget.offsetHeight + 'px'

    this.map = new mapboxgl.Map({
      container: this.mapTarget, // HTML container id
      style: 'mapbox://styles/bonddmitry/cl5avivdq00ak14qpqit5235u', // style URL
      center: center,
      zoom: zoom,
      scrollZoom: {
        around: 'center'
      }
    });

    this.map.keyboard.disable();
    this.map.boxZoom.disable();
    this.map.dragRotate.disable();
    this.map.touchPitch.disable();
    this.map.doubleClickZoom.disable();

    this.map.touchZoomRotate.enable({around: 'center'});
    this.map.touchZoomRotate.disableRotation();
    this.map.scrollZoom.enable({around: 'center'});

    /*geocoder.setRenderFunction((json) => {
      console.log(json)
      const address = json.address ? json.address : ''
      return `<div><div class="text-bold">${json.text} ${address}</div><div class="text-gray-500 text-sm">${json.place_name}</div></div>`
    })*/

    this.map.easeTo({center: [180, 5], duration: 60000 * 2});
    this.map.on('move', this.onMove.bind(this))
    this.map.on('zoom', this.onZoom.bind(this))

    /*geocoder.on('result', () => {
      this.exactValue = true
      this.exactLocationTarget.checked = true
      this.changeStateTo('exact')
    });*/
  }

  initializeMarker(center) {
    if (this.marker) {
      return
    }
    const marker = document.getElementById('map-marker');
    this.marker = new mapboxgl.Marker(marker)
    this.marker.setLngLat(this.map.getCenter()).addTo(this.map);
  }

  changeExact(ev) {
    this.exactValue = ev.target.checked
    this.resultExactLocationTarget.value = this.exactValue

    if (this.exactValue) {
      this.resultRadiusTarget.value = 0
    }
    this.changeStateTo(this.exactValue ? 'exact' : 'approximate', true)
  }

  changeAutocomplete(ev) {
    const MIN_ADDRESS_LENGTH = 3
    const DEBOUNCE_DELAY = 500

    const currentValue = this.autocompleteTarget.value;

    // Cancel previous timeout
    if (this.autocompleteTimeout) {
      clearTimeout(this.autocompleteTimeout);
    }

    // Cancel previous request promise
    if (this.autocompletePromiseReject) {
      this.autocompletePromiseReject({
        canceled: true
      });
    }

    // Skip empty or short address strings
    if (!currentValue || currentValue.length < MIN_ADDRESS_LENGTH) {
      return false;
    }

    /* Call the Address Autocomplete API with a delay */
    this.autocompleteTimeout = setTimeout(() => {
    	this.autocompleteTimeout = null;

      /* Create a new promise and send geocoding request */
      const promise = new Promise((resolve, reject) => {
        this.autocompletePromiseReject = reject;

        var url = `https://api.geoapify.com/v1/geocode/autocomplete?text=${encodeURIComponent(currentValue)}&format=json&limit=5&apiKey=${this.geoapifyKey}`;

        fetch(url)
          .then(response => {
            this.autocompletePromiseReject = null;

            // check if the call was successful
            if (response.ok) {
              response.json().then(data => resolve(data));
            } else {
              response.json().then(data => reject(data));
            }
          });
      });

      promise.then((data) => {
        // here we get address suggestions
        console.log(data);

        var currentItems = data.results;
        this.closeAutocomplete()

        /*create a DIV element that will contain the items (values):*/
        const autocompleteItemsElement = document.createElement("div");
        autocompleteItemsElement.setAttribute("class", "autocomplete-items flex flex-col bg-white p-2 gap-2 mt-2 rounded-md shadow-md absolute left-0 top-[65px] m-4 w-[calc(100%-2rem)]");
        this.autocompleteContainerTarget.appendChild(autocompleteItemsElement);

        if (data.results.length) {
          this.autocompleteClearTarget.classList.remove('hidden')
        }

        /* For each item in the results */
        data.results.forEach((result, index) => {
          /* Create a DIV element for each element: */
          const itemElement = document.createElement("div");
          /* Set formatted address as item value */
          itemElement.innerHTML = `<div class="hover:bg-gray-200 cursor-pointer p-2"><div class="text-bold">${result.address_line1}</div><div class="text-gray-500 text-sm">${result.formatted}</div></div>`;
          autocompleteItemsElement.appendChild(itemElement);

          itemElement.addEventListener("click", (e) => {
            this.autocompleteTarget.value = currentItems[index].address_line1;
            this.resultLocationTarget.value = currentItems[index].address_line1;
            this.autocompleteItemSelected(currentItems[index]);
            /* Close the list of autocompleted values: */
            this.closeAutocomplete();
          });
        });
      }, (err) => {
        if (!err.canceled) {
          console.log(err);
        }
      });
    }, DEBOUNCE_DELAY);
  }

  autocompleteItemSelected(item) {
    this.map.flyTo({
      center: [item.lon, item.lat],
      zoom: 15,
      maxDuration: 1000
    })
    this.flying = true
    setTimeout(() => {
      this.flying = false
    }, 1500)
    console.log("Selected", item)
  }

  closeAutocomplete() {
    var autocompleteItemsElement = this.autocompleteContainerTarget.querySelectorAll(".autocomplete-items");
    if (autocompleteItemsElement) {
      autocompleteItemsElement.forEach(e => e.remove())
    }
    this.autocompleteClearTarget.classList.add('hidden')
  }

  changeStateTo(state, zoomTo=false) {
    this.state = state
    this.initializeMarker()
    this.calculateControlsVisibility()

    if (this.state == 'exact') {
      this.markerTarget.classList.add('is-exact')

      if (zoomTo) {
        this.map.zoomTo(this.preciseModeZoom);
      }
    } else if (this.state == 'approximate') {
      this.markerTarget.classList.remove('is-exact')

      if (zoomTo) {
        this.map.zoomTo(this.approximateModeZoom);
      }
    }
  }

  calculateControlsVisibility() {
    if (this.zoom <= this.controlsAppearZoom) {
      if (this.marker) {
        this.marker._element.classList.add('hidden')
      }
      this.saveButtonTarget.classList.add('hidden')
      if (this.hasExactLocationContainerTarget) {
        this.exactLocationContainerTarget.classList.add('hidden')
      }
    } else {
      if (this.marker) {
        this.marker._element.classList.remove('hidden')
      }

      if (this.stoppedMoving) {
        this.saveButtonTarget.classList.remove('hidden')
      } else {
        this.saveButtonTarget.classList.add('hidden')
      }
      if (this.hasExactLocationContainerTarget) {
        this.exactLocationContainerTarget.classList.remove('hidden')
      }
    }
  }

  startMoving() {
    if (this.movingTimeout) {
      clearTimeout(this.movingTimeout)
    }

    this.stoppedMoving = false
    this.movingTimeout = setTimeout(this.stopMoving.bind(this), 500)
    this.calculateControlsVisibility()
  }

  stopMoving() {
    this.stoppedMoving = true

    this.calculateControlsVisibility()

    if (this.zoom <= this.controlsAppearZoom) {
      this.autocompleteTarget.value = ''
      return
    }

    const point = this.map.getCenter()

    const detail = this.exactValue ? '' : '&type=street'
    const proximity = this.zoom > this.controlsAppearZoom ? `&bias=proximity:${point.lng},${point.lat}}` : ''
    const reverseGeocodingUrl = `https://api.geoapify.com/v1/geocode/reverse?lat=${point.lat}&lon=${point.lng}&apiKey=${this.geoapifyKey}${detail}${proximity}`;

    fetch(reverseGeocodingUrl).then(result => result.json())
      .then(featureCollection => {
        if (featureCollection.features.length > 0) {
          const props = featureCollection.features[0].properties

          if (this.exactValue) {
            this.autocompleteTarget.value = props['formatted']
          } else {
            const result = props['result_type'] != 'postcode' ? props[props['result_type']] : null
            this.autocompleteTarget.value = props['suburb'] || props['name'] || result || props['street'] || props['formatted']
          }

          this.resultLocationTarget.value = this.autocompleteTarget.value
        }
      });
  }

  onMove(ev) {
    const center = this.map.getCenter()

    this.resultLatTarget.value = center.lat
    this.resultLngTarget.value = center.lng
    this.marker.setLngLat(center)

    if (this.zoom <= this.controlsAppearZoom) {
      return
    }
    if (this.flying) {
      return
    }
    this.startMoving()

    if (this.marker) {
      this.calculateDistance()
    }
  }

  onZoom(ev) {
    this.zoom = this.map.getZoom()
    this.calculateControlsVisibility()
  }

  calculateDistance() {
    const fromPoint = this.map.unproject([this.mapTarget.offsetWidth / 2, this.mapTarget.offsetHeight / 2])
    const toPoint = this.map.unproject([this.mapTarget.offsetWidth / 2 + 64, this.mapTarget.offsetHeight / 2])

    const from = turf.point([fromPoint.lng, fromPoint.lat]);
    const to = turf.point([toPoint.lng, toPoint.lat]);

    var distance = turf.distance(from, to)

    this.resultRadiusTarget.value = distance * 1000

    var unit = 'km'

    if (distance < 1) {
      distance = (distance * 1000).toFixed(0)
      unit = 'm'
    } else {
      distance = distance.toFixed(1)
    }
    this.distanceTarget.innerHTML = distance
    this.distanceUnitsTarget.innerHTML = unit
  }
}
