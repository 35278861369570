import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['number', 'hidden', 'optionalCheckbox']

  static values = {
    number: Number
  }

  connect() {
    this.changeOptional()
  }

  add() {
    this.numberValue = (this.numberValue || 0) + 1
    this.optional = false
    this.updateFields()
  }

  subtract() {
    if (this.numberValue) {
      this.numberValue = (this.numberValue || 0) - 1
      this.optional = false
      this.updateFields()
    }
  }

  updateFields() {
    if (this.optional) {
      this.numberTarget.classList.add('opacity-30')
      this.numberTarget.innerHTML = '0'
      this.hiddenTarget.value = null
      return
    }

    this.numberTarget.classList.remove('opacity-30')
    this.numberTarget.innerHTML = this.numberValue
    this.hiddenTarget.value = this.numberValue
    this.optionalCheckboxTarget.checked = false
  }

  changeOptional() {
    if (!this.hasOptionalCheckboxTarget) {
      return
    }

    if (this.optionalCheckboxTarget.checked) {
      this.optional = true
    } else {
      this.optional = false
      this.numberValue = 0
    }

    this.updateFields()
  }
}
