import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['textField', 'checkbox']

  connect() {
    this.checkboxTarget.checked = this.textFieldTarget.value.length == 0
  }

  onChangeCheckbox(ev) {
    console.log(ev)
    if (this.checkboxTarget.checked) {
      this.textFieldTarget.value = ''
    } else {
      this.textFieldTarget.value = 0
    }
  }

  onChangeText(ev) {
    if (this.textFieldTarget.value.length == 0) {
      this.checkboxTarget.checked = true
    } else {
      this.checkboxTarget.checked = false
    }
  }
}
