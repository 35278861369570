import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['haveListings', 'haveTab', 'searchListings', 'searchTab']

  showHave() {
    this.searchTabTarget.classList.remove('is-active')
    this.haveTabTarget.classList.add('is-active')

    this.searchTabTarget.classList.add('is-inactive')
    this.haveTabTarget.classList.remove('is-inactive')

    this.searchListingsTarget.classList.add('hidden')
    this.haveListingsTarget.classList.remove('hidden')
  }

  showSearch() {
    this.searchTabTarget.classList.add('is-active')
    this.haveTabTarget.classList.remove('is-active')

    this.searchTabTarget.classList.remove('is-inactive')
    this.haveTabTarget.classList.add('is-inactive')

    this.searchListingsTarget.classList.remove('hidden')
    this.haveListingsTarget.classList.add('hidden')
  }
}
