import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['container', 'map', 'marker']

  static values = {
    lat: String,
    lng: String,
    exact: Boolean,
    radius: Number
  }

  addMap(lat, lng) {
    mapboxgl.accessToken = 'pk.eyJ1IjoiYm9uZGRtaXRyeSIsImEiOiJjanJ3OTh2Z2MwOWg1M3luaG96YzUwdW1yIn0.lXfszgM-i08_2swfUlKWxw';

    this.map = new mapboxgl.Map({
      container: this.mapTarget, // HTML container id
      style: 'mapbox://styles/bonddmitry/cl5avivdq00ak14qpqit5235u', // style URL
      center: [lng, lat], // starting position as [lng, lat]
      zoom: 12
    });

    if (this.exactValue) {
      const marker = document.getElementById('map-marker');

      new mapboxgl.Marker({
        element: marker
      }).setLngLat([lng, lat]).addTo(this.map);

      this.markerTarget.classList.add('is-exact')
      this.markerTarget.classList.remove('hidden')
    } else {
      var radius = this.radiusValue;
      var center = [lng, lat];
      var options = { steps: 50, units: "meters" };
      var circle = turf.circle(center, radius, options);
      var bbox = turf.bbox(circle)

      this.bbox = bbox
      this.map.on('load', () => {
        // Add a data source containing GeoJSON data.
        this.map.addSource('property-area', {
          'type': 'geojson',
          'data': circle
        })

        this.map.addLayer({
          'id': 'property-area',
          'type': 'fill',
          'source': 'property-area', // reference the data source
          'layout': {},
          'paint': {
            'fill-color': '#3891fb',
            'fill-opacity': 0.2
          }
        });

        this.map.addLayer({
          'id': 'outline',
          'type': 'line',
          'source': 'property-area',
          'layout': {},
          'paint': {
            'line-color': '#38517b',
            'line-width': 2
          }
        });

        this.map.fitBounds(bbox, { padding: 20 } )
      })
    }
  }

  openFullscreen() {
    this.containerTarget.classList.add('is-shown')
    if (!this.mapCreated) {
      this.addMap(this.latValue, this.lngValue)
      this.mapCreated = true
    } else {
      this.map.fitBounds(this.bbox, { padding: 20 } )
    }
  }

  closeFullscreen() {
    this.containerTarget.classList.remove('is-shown')
  }
}
